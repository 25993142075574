import { Alert, Snackbar } from '@mui/material';
import { FallbackRender } from '@sentry/react/dist/errorboundary';

export const ErrorFallback: FallbackRender = ({ error, eventId, resetError }) => {
  if (isChunkLoadError(error)) {
    setTimeout(() => window.location.reload(), 1000);
  }

  return (
    <Snackbar open={!!error} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={resetError}>
      <Alert severity="error" onClose={resetError}>{`Error occured. Reference id: ${eventId}.`}</Alert>
    </Snackbar>
  );
};

function isChunkLoadError(error: Error) {
  if (!error || typeof error !== 'object') {
    return false;
  }

  const jsChunkLoadError = 'name' in error && error.name === 'ChunkLoadError';
  const cssChunkLoadError = 'code' in error && (error as any).code === 'CSS_CHUNK_LOAD_FAILED';

  return jsChunkLoadError || cssChunkLoadError;
}
