import { styled } from '@mui/material/styles';
import { useCallback, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { DashboardSidebar } from '../sidebar/DashboardSidebar';
import { DashboardNavbar } from './DashboardNavbar';

export const DashboardLayout = () => {
  const [open, setOpen] = useState(false);
  const handleOpenSidebar = useCallback(() => setOpen(true), []);
  const handleCloseSidebar = useCallback(() => setOpen(false), []);

  const [notificationBarHeight, setNotificationBarHeight] = useState<number>(0);
  const handleNotificationBarHeightChange = (height: number) => setNotificationBarHeight(height);

  return (
    <RootStyle>
      <DashboardNavbar
        onOpenSidebar={handleOpenSidebar}
        handleNotificationBarHeightChange={handleNotificationBarHeightChange}
      />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={handleCloseSidebar} />
      <MainStyle demoBarHeight={notificationBarHeight}>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
};

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div', {
  shouldForwardProp: key => key !== 'demoBarHeight',
})<{
  demoBarHeight: number;
}>(({ theme, demoBarHeight }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + demoBarHeight + 24,
  paddingBottom: theme.spacing(10),
  transition: theme.transitions.create('margin', { duration: theme.transitions.duration.complex }),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + demoBarHeight + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));
