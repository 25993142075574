import { CssBaseline } from '@mui/material';
import { createTheme, StyledEngineProvider, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import { useSettings } from 'app/contexts/SettingsContext';
import { ReactNode, useMemo } from 'react';
import { breakpoints } from './breakpoints';
import { ComponentsOverrides } from './overrides';
import { palette } from './palette';
import { customShadows, shadows } from './shadows';
import { shape } from './shape';
import { typography } from './typography';

type ThemeConfigProps = {
  children: ReactNode;
};

export function ThemeConfig({ children }: ThemeConfigProps) {
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: isLight ? { ...palette.light, mode: 'light' } : { ...palette.dark, mode: 'dark' },
      shape,
      typography,
      breakpoints,
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
    }),
    [isLight],
  );

  const theme = createTheme(themeOptions);
  theme.components = ComponentsOverrides(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
