import * as yup from 'yup';

const fieldRequired: string = 'Field is required';
const passwordErrorMessage: string =
  'Pass must have: a capital letter, a lower case letter, a number and be at least 8 symbols long';

export const employeeRegistrationSchema = yup
  .object()
  .shape({
    password: yup
      .string()
      .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, passwordErrorMessage)
      .required(fieldRequired),
    repeatPassword: yup
      .string()
      .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, passwordErrorMessage)
      .required(fieldRequired)
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
    agreeToTermsAndPrivacy: yup.bool().oneOf([true], 'You must agree').required('You must agree').default(false),
  })
  .required();

export type EmployeeRegistrationSchema = yup.Asserts<typeof employeeRegistrationSchema>;
