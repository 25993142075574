import { adminRoutes, ADMIN_ROUTES } from 'admin/routes';
import { discomeAdminRoutes, DISCOME_ADMIN_ROUTES } from 'discomeAdmin/routes';
import { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { userRoutes, USER_ROUTES } from 'user/routes';
import { LogoOnlyLayout } from '../../ui/layouts/LogoOnlyLayout';
import { loadable } from './loadable';
import { routePaths } from './paths';
import { AuthenticatedDashboard } from './protected';

export const EXTERNAL_PATHS = {
  termsAndConditions: 'https://static.benme.io/discome_terms_and_conditions.pdf',
  privacyPolicy: 'https://static.benme.io/discome_privacy_policy.pdf',
  logoFull: 'https://discome.io/static/brand/logo_full.svg',
};

const routes = {
  root: '/',
  notFound: '404',
  authorize: 'authorize',
  user: USER_ROUTES,
  admin: ADMIN_ROUTES,
  discomeAdmin: DISCOME_ADMIN_ROUTES,
};

export const ROUTE_PATHS = routePaths(routes);

export const Router = () => {
  return useRoutes([
    {
      path: routes.root,
      element: <AuthenticatedDashboard />,
      children: [
        { index: true, element: <Navigate to={ROUTE_PATHS.user.allDiscounts} replace /> },
        userRoutes(),
        adminRoutes(),
        discomeAdminRoutes(),
      ],
    },
    { path: routes.authorize, element: <Navigate to={routes.root} replace /> },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: routes.notFound, element: <NotFound /> },
        { path: '*', element: <Navigate to={ROUTE_PATHS.notFound} replace /> },
      ],
    },
  ]);
};

const NotFound = loadable(
  lazy(() => import('../../not-found/NotFoundPage').then(({ NotFoundPage }) => ({ default: NotFoundPage }))),
);
