import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Alert, IconButton, InputAdornment, Link, Stack } from '@mui/material';
import { useRegisterUser } from 'api/registration/api';
import { UserRegistrationDto } from 'api/registration/types';
import { ServerValidationError } from 'api/types';
import { EXTERNAL_PATHS } from 'app/router/Router';
import { setFormFieldErrors } from 'app/utils/setFormErrors';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormCheckboxField } from 'ui/form/FormCheckboxField';
import { FormTextField } from 'ui/form/FormTextField';
import Iconify from 'ui/Iconify';
import { employeeRegistrationSchema, EmployeeRegistrationSchema } from './validation';

type Props = {
  onSuccess: () => void;
  invitationCode: string;
};

export default function RegisterForm({ onSuccess, invitationCode }: Props) {
  const [errors, setErrors] = useState<string[]>();
  const registerUser = useRegisterUser();
  const { enqueueSnackbar } = useSnackbar();

  const { control, handleSubmit, setError, register } = useForm<EmployeeRegistrationSchema>({
    mode: 'onChange',
    resolver: yupResolver(employeeRegistrationSchema),
    defaultValues: employeeRegistrationSchema.getDefault(),
  });

  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = (data: UserRegistrationDto) => {
    registerUser.mutate(data, {
      onSuccess: () => {
        enqueueSnackbar('Registracija sėkminga', { variant: 'success' });
        onSuccess();
      },
      onError: () => {
        enqueueSnackbar('Įvyko klaida', { variant: 'error' });
      },
    });
  };

  useEffect(() => {
    const serverErrors = (registerUser.error as any)?.response?.data as ServerValidationError;
    if (serverErrors) {
      setErrors(serverErrors.errors);
      setFormFieldErrors(serverErrors, setError);
    }
  }, [registerUser, setErrors, setError]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {errors?.map(x => (
          <Alert severity="error">{x}</Alert>
        ))}
        <input type="hidden" value={invitationCode} {...register('invitationCode')} />
        <FormTextField
          control={control}
          name="password"
          label="Slaptažodis"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <FormTextField control={control} name="repeatPassword" label="Pakartoti slaptažodį" type="password" />
        <FormCheckboxField
          control={control}
          name="agreeToTermsAndPrivacy"
          label={
            <>
              Aš sutinku su{' '}
              <Link underline="always" color="text.primary" href={EXTERNAL_PATHS.termsAndConditions}>
                Naudojimosi sąlygomis
              </Link>{' '}
              and{' '}
              <Link underline="always" color="text.primary" href={EXTERNAL_PATHS.privacyPolicy}>
                Privatumo politika
              </Link>
              .
            </>
          }
        />

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={registerUser.isLoading}>
          Registruotis
        </LoadingButton>
      </Stack>
    </form>
  );
}
