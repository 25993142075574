// scroll bar
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { App } from 'App';
import { configureI18n } from 'app/i18n';
import axios from 'axios';
import i18next from 'i18next';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import 'react-quill/dist/quill.snow.css';
import { BrowserRouter } from 'react-router-dom';
import { Sentry } from 'sentry';
import 'simplebar/src/simplebar.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { SettingsProvider } from './app/contexts/SettingsContext';
import './app/utils/highlight';
import { reportWebVitals } from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

(async () => {
  await configureI18n(i18next, {
    react: { useSuspense: true },
    debug: process.env.NODE_ENV !== 'production',
  });

  if (process.env.NODE_ENV === 'production') {
    Sentry.initialize();
  }

  axios.defaults.baseURL = process.env.REACT_APP_DISCOUNT_PLATFORM_URL + '/api';

  ReactDOM.render(
    <StrictMode>
      <HelmetProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SettingsProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </SettingsProvider>
        </LocalizationProvider>
      </HelmetProvider>
    </StrictMode>,
    document.getElementById('root'),
  );
})();

serviceWorkerRegistration.register();
reportWebVitals();
