import { loadable } from 'app/router/loadable';
import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router';
import { USER_ROUTES } from './UserManagement/routes';

export const ADMIN_ROUTES = {
  root: 'admin',
  users: 'users',
  invoices: 'invoices',
  invoice: 'invoice/:invoiceId',
  settings: 'settings',
};

export function adminRoutes(): RouteObject {
  return {
    path: ADMIN_ROUTES.root,
    children: [
      {
        index: true,
        element: <Navigate to={ADMIN_ROUTES.users} />,
      },
      {
        path: ADMIN_ROUTES.users,
        children: USER_ROUTES,
      },
      {
        path: ADMIN_ROUTES.settings,
        element: <SettingsPage />,
      },
      {
        path: ADMIN_ROUTES.invoices,
        element: <InvoicesPage />,
      },
      {
        path: ADMIN_ROUTES.invoice,
        element: <ViewInvoicePage />,
      },
    ],
  };
}

const InvoicesPage = loadable(
  lazy(() =>
    import('./Invoices/InvoicesPage').then(({ InvoicesPage }) => ({
      default: InvoicesPage,
    })),
  ),
);

const ViewInvoicePage = loadable(
  lazy(() =>
    import('./Invoices/View/InvoicePage').then(({ InvoicePage: ViewInvoicePage }) => ({
      default: ViewInvoicePage,
    })),
  ),
);

const SettingsPage = loadable(
  lazy(() =>
    import('./Settings/SettingsPage').then(({ SettingsPage: SettingsPage }) => ({
      default: SettingsPage,
    })),
  ),
);
