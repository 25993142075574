import { USER_ROUTES } from 'admin/UserManagement/routes';
import { loadable } from 'app/router/loadable';
import { ROUTE_PATHS } from 'app/router/Router';
import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router';

export const DISCOME_ADMIN_ROUTES = {
  root: 'discome-admin',
  dashboard: 'dashboard',
  createTenant: 'create-tenant',
  editTenant: 'tenants/edit/:tenantId',
  manage: 'manage/:tenantId',
  invoices: 'invoices',
  invoice: 'invoices/:invoiceId',
  uploadInvoice: 'invoices/upload-invoice/:tenantId/:year/:month',
  discounts: 'discounts',
  createDiscount: 'discounts/create',
  editDiscount: 'discounts/edit/:discountId',
  addDiscountCodes: 'discounts/:discountId/add-codes',
  discountCategories: 'discount-categories',
  createDiscountCategory: 'discount-categories/create',
  editDiscountCategory: 'discount-categories/edit/:discountCategoryId',
};

export function discomeAdminRoutes(): RouteObject {
  return {
    path: DISCOME_ADMIN_ROUTES.root,
    children: [
      { index: true, element: <Navigate to={ROUTE_PATHS.discomeAdmin.dashboard} /> },
      { path: DISCOME_ADMIN_ROUTES.dashboard, element: <DiscomeAdminDashboardPage /> },
      { path: DISCOME_ADMIN_ROUTES.createTenant, element: <CreateTenantPage /> },
      { path: DISCOME_ADMIN_ROUTES.editTenant, element: <EditTenantPage /> },
      {
        path: DISCOME_ADMIN_ROUTES.manage,
        children: [{ path: 'users', children: USER_ROUTES }],
      },
      { path: DISCOME_ADMIN_ROUTES.invoices, element: <InvoiceManagementPage /> },
      { path: DISCOME_ADMIN_ROUTES.discounts, element: <DiscountManagementPage /> },
      { path: DISCOME_ADMIN_ROUTES.createDiscount, element: <CreateDiscountPage /> },
      { path: DISCOME_ADMIN_ROUTES.editDiscount, element: <EditDiscountPage /> },
      { path: DISCOME_ADMIN_ROUTES.addDiscountCodes, element: <AddDiscountCodesPage /> },
      { path: DISCOME_ADMIN_ROUTES.discountCategories, element: <DiscountCategoriesManagementPage /> },
      { path: DISCOME_ADMIN_ROUTES.createDiscountCategory, element: <CreateDiscountCategoryPage /> },
      { path: DISCOME_ADMIN_ROUTES.editDiscountCategory, element: <EditDiscountCategoryPage /> },
    ],
  };
}

const DiscomeAdminDashboardPage = loadable(
  lazy(() =>
    import('./Dashboard/DiscomeAdminDashboardPage').then(({ DiscomeAdminDashboardPage }) => ({
      default: DiscomeAdminDashboardPage,
    })),
  ),
);

const CreateTenantPage = loadable(
  lazy(() =>
    import('./Dashboard/CreateTenantPage').then(({ CreateTenantPage }) => ({
      default: CreateTenantPage,
    })),
  ),
);

const EditTenantPage = loadable(
  lazy(() =>
    import('./Dashboard/EditTenantPage').then(({ EditTenantPage }) => ({
      default: EditTenantPage,
    })),
  ),
);

const InvoiceManagementPage = loadable(
  lazy(() =>
    import('./Invoices/InvoiceManagementPage').then(({ InvoiceManagementPage }) => ({
      default: InvoiceManagementPage,
    })),
  ),
);

const DiscountManagementPage = loadable(
  lazy(() =>
    import('./Discounts/DiscountManagementPage').then(({ DiscountManagementPage }) => ({
      default: DiscountManagementPage,
    })),
  ),
);

const CreateDiscountPage = loadable(
  lazy(() =>
    import('./Discounts/CreateDiscountPage').then(({ CreateDiscountPage }) => ({
      default: CreateDiscountPage,
    })),
  ),
);

const EditDiscountPage = loadable(
  lazy(() =>
    import('./Discounts/EditDiscountPage').then(({ EditDiscountPage }) => ({
      default: EditDiscountPage,
    })),
  ),
);

const AddDiscountCodesPage = loadable(
  lazy(() =>
    import('./Discounts/AddDiscountCodes/AddDiscountCodesPage').then(({ AddDiscountCodesPage }) => ({
      default: AddDiscountCodesPage,
    })),
  ),
);

const DiscountCategoriesManagementPage = loadable(
  lazy(() =>
    import('./DiscountCategories/DiscountCategoriesManagementPage').then(({ DiscountCategoriesManagementPage }) => ({
      default: DiscountCategoriesManagementPage,
    })),
  ),
);

const CreateDiscountCategoryPage = loadable(
  lazy(() =>
    import('./DiscountCategories/CreateDiscountCategoryPage').then(({ CreateDiscountCategoryPage }) => ({
      default: CreateDiscountCategoryPage,
    })),
  ),
);

const EditDiscountCategoryPage = loadable(
  lazy(() =>
    import('./DiscountCategories/EditDiscountCategoryPage').then(({ EditDiscountCategoryPage }) => ({
      default: EditDiscountCategoryPage,
    })),
  ),
);
