import { ServerValidationError } from 'api/types';
import { UseFormSetError } from 'react-hook-form';

export function setFormFieldErrors(errors: ServerValidationError, setError: UseFormSetError<any>) {
  if (errors?.fieldErrors) {
    for (const [key, value] of Object.entries(errors.fieldErrors)) {
      setError(key, { message: value[0] });
    }
  }
}
