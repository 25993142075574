import { ROUTE_PATHS } from 'app/router/Router';
import { TFunction, useTranslation } from 'react-i18next';
import { FeatureConfig } from 'types';
import { DynamicIcon } from 'ui/DynamicIcon';

export type SidebarConfig = {
  subheader?: string;
  items: Item[];
};

export type ChildItem = {
  title: string;
  path: string;
} & FeatureConfig;

export type Item = {
  icon?: JSX.Element;
  children?: ChildItem[];
} & ChildItem;

export const getMuiIcon = (name: string) => <DynamicIcon name={name} />;

export const userSidebarConfig = (categories: Item[]): SidebarConfig => {
  const { t } = useTranslation();

  return {
    subheader: t('categories'),
    items: [
      {
        title: t('all'),
        path: ROUTE_PATHS.user.allDiscounts,
        icon: getMuiIcon('LocalOfferTwoTone'),
      },
      {
        title: t('redeemed'),
        path: ROUTE_PATHS.user.myDiscounts,
        icon: getMuiIcon('RedeemTwoTone'),
      },
      ...categories,
    ],
  };
};

export const adminSidebarConfig = (t: TFunction): SidebarConfig => ({
  subheader: t('admin'),
  items: [
    {
      title: t('users'),
      path: ROUTE_PATHS.admin.users,
      icon: getMuiIcon('PeopleAltTwoTone'),
    },
    {
      title: t('invoices'),
      path: ROUTE_PATHS.admin.invoices,
      icon: getMuiIcon('DescriptionTwoTone'),
    },
    {
      title: t('settings'),
      path: ROUTE_PATHS.admin.settings,
      icon: getMuiIcon('SettingsTwoTone'),
    },
  ],
});

export const discomeAdminSidebarConfig = (t: TFunction): SidebarConfig => ({
  subheader: 'DISCOME ADMIN',
  items: [
    {
      title: t('dashboard'),
      path: ROUTE_PATHS.discomeAdmin.dashboard,
      icon: getMuiIcon('DashboardTwoTone'),
    },
    {
      title: 'Invoices',
      path: ROUTE_PATHS.discomeAdmin.invoices,
      icon: getMuiIcon('DescriptionTwoTone'),
    },
    {
      title: 'Discounts',
      path: ROUTE_PATHS.discomeAdmin.discounts,
      icon: getMuiIcon('LocalOfferTwoTone'),
      children: [
        { title: 'Discounts', path: ROUTE_PATHS.discomeAdmin.discounts },
        { title: 'Categories', path: ROUTE_PATHS.discomeAdmin.discountCategories },
      ],
    },
  ],
});
