import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export function Skeleton(theme: Theme) {
  return {
    MuiSkeleton: {
      defaultProps: {
        animation: 'wave',
      },

      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.neutral,
          '&::after': {
            background: 'linear-gradient(90deg, transparent, rgba(145, 158, 171, 0.30), transparent)',
          },
        },
      },
    },
  };
}
