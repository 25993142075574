// material
import { BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export function LogoFull({ sx }: BoxProps) {
  return (
    <svg width="200" viewBox="0 0 50 11" fill="#00ab55" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.93998 10.18C2.46798 10.18 2.04798 10.06 1.67998 9.81998C1.31198 9.57198 1.01998 9.21198 0.80398 8.73998C0.58798 8.25998 0.47998 7.67998 0.47998 6.99998C0.47998 5.97598 0.71598 5.19198 1.18798 4.64798C1.66798 4.09598 2.31198 3.81998 3.11998 3.81998C3.50398 3.81998 3.83998 3.89998 4.12798 4.05998C4.24798 4.11598 4.37198 4.19598 4.49998 4.29998V1.95998L3.77998 1.77998V1.59998H5.33998C6.05998 1.59998 6.41998 1.95998 6.41998 2.67998V9.63998L7.13998 9.81998V9.99998H6.05998C5.57998 9.99998 5.20798 9.85198 4.94398 9.55598C4.83198 9.43598 4.74398 9.28398 4.67998 9.09998C4.57598 9.29998 4.44398 9.47998 4.28398 9.63998C3.92398 9.99998 3.47598 10.18 2.93998 10.18ZM3.41998 9.75998C3.71598 9.75998 3.98398 9.59998 4.22398 9.27998C4.31198 9.15998 4.40398 8.99998 4.49998 8.79998V4.65998C4.41198 4.57198 4.31998 4.49198 4.22398 4.41998C4.00798 4.25998 3.79998 4.17998 3.59998 4.17998C3.25598 4.17998 2.98798 4.37998 2.79598 4.77998C2.61198 5.17998 2.51998 5.91998 2.51998 6.99998C2.51998 8.03198 2.60398 8.75198 2.77198 9.15998C2.94798 9.55998 3.16398 9.75998 3.41998 9.75998Z" />
      <path d="M7.92139 9.81998L8.64139 9.63998V4.35998L7.92139 4.17998V3.99998H9.48139C10.2014 3.99998 10.5614 4.35998 10.5614 5.07998V9.63998L11.2814 9.81998V9.99998H7.92139V9.81998Z" />
      <path d="M14.8195 10.18C14.3235 10.18 13.8795 10.072 13.4875 9.85598C13.2955 9.75198 13.1395 9.63998 13.0195 9.51998C12.8915 9.69598 12.7515 9.83198 12.5995 9.92798C12.5275 9.97598 12.4475 10.02 12.3595 10.06H12.0595V8.01998H12.5395C12.6435 8.35598 12.7955 8.66798 12.9955 8.95598C13.4195 9.57198 13.9475 9.87998 14.5795 9.87998C14.9155 9.87998 15.1675 9.79998 15.3355 9.63998C15.5115 9.47198 15.5995 9.23198 15.5995 8.91998C15.5995 8.65598 15.4795 8.43598 15.2395 8.25998C15.0075 8.07598 14.6395 7.85198 14.1355 7.58798C13.7035 7.37198 13.3515 7.17998 13.0795 7.01198C12.8155 6.83598 12.5875 6.61998 12.3955 6.36398C12.2115 6.09998 12.1195 5.79198 12.1195 5.43998C12.1195 4.95998 12.3235 4.57198 12.7315 4.27598C13.1475 3.97198 13.7835 3.81998 14.6395 3.81998C15.0635 3.81998 15.4555 3.93198 15.8155 4.15598C15.9915 4.25998 16.1435 4.36798 16.2715 4.47998C16.3755 4.33598 16.5155 4.19998 16.6915 4.07198C16.8275 3.99198 16.9075 3.94798 16.9315 3.93998H17.2315V5.97998H16.7515C16.6315 5.63598 16.4835 5.32798 16.3075 5.05598C15.9075 4.43198 15.4315 4.11998 14.8795 4.11998C14.6635 4.11998 14.4795 4.19998 14.3275 4.35998C14.1755 4.51998 14.0995 4.71998 14.0995 4.95998C14.0995 5.26398 14.2275 5.51998 14.4835 5.72798C14.7395 5.92798 15.1275 6.15998 15.6475 6.42398C16.0715 6.63998 16.4075 6.83198 16.6555 6.99998C16.9115 7.15998 17.1275 7.35998 17.3035 7.59998C17.4875 7.83998 17.5795 8.11998 17.5795 8.43998C17.5795 9.59998 16.6595 10.18 14.8195 10.18Z" />
      <path d="M21.72 10.18C20.696 10.18 19.9 9.89998 19.332 9.33998C18.764 8.77198 18.48 7.99198 18.48 6.99998C18.48 6.00798 18.764 5.23198 19.332 4.67198C19.9 4.10398 20.696 3.81998 21.72 3.81998C22.456 3.81998 23.008 3.94798 23.376 4.20398C23.752 4.45998 23.94 4.77198 23.94 5.13998C23.94 5.41198 23.856 5.63198 23.688 5.79998C23.528 5.95998 23.312 6.03998 23.04 6.03998C22.928 6.03998 22.816 6.03198 22.704 6.01598L22.56 5.97998V4.17998L22.392 4.11998C22.272 4.07998 22.128 4.05998 21.96 4.05998C21.504 4.05998 21.148 4.27598 20.892 4.70798C20.644 5.13998 20.52 5.90398 20.52 6.99998C20.52 8.06398 20.644 8.81198 20.892 9.24398C21.148 9.66798 21.504 9.87998 21.96 9.87998C22.272 9.87998 22.56 9.80798 22.824 9.66398C23.096 9.51198 23.308 9.31598 23.46 9.07598C23.62 8.82798 23.7 8.57598 23.7 8.31998H24C24 8.63998 23.904 8.94398 23.712 9.23198C23.52 9.51998 23.252 9.75198 22.908 9.92798C22.564 10.096 22.168 10.18 21.72 10.18Z" />
      <path d="M28.0233 10.18C27.0313 10.18 26.2513 9.89998 25.6833 9.33998C25.1233 8.77198 24.8433 7.99198 24.8433 6.99998C24.8433 6.00798 25.1233 5.23198 25.6833 4.67198C26.2513 4.10398 27.0313 3.81998 28.0233 3.81998C29.0153 3.81998 29.7913 4.10398 30.3513 4.67198C30.9193 5.23198 31.2033 6.00798 31.2033 6.99998C31.2033 7.99198 30.9193 8.77198 30.3513 9.33998C29.7913 9.89998 29.0153 10.18 28.0233 10.18ZM28.0233 9.93998C28.3433 9.93998 28.6113 9.71598 28.8273 9.26798C29.0513 8.81998 29.1633 8.06398 29.1633 6.99998C29.1633 5.93598 29.0513 5.17998 28.8273 4.73198C28.6113 4.28398 28.3433 4.05998 28.0233 4.05998C27.7033 4.05998 27.4313 4.28398 27.2073 4.73198C26.9913 5.17998 26.8833 5.93598 26.8833 6.99998C26.8833 8.06398 26.9913 8.81998 27.2073 9.26798C27.4313 9.71598 27.7033 9.93998 28.0233 9.93998Z" />
      <path d="M32.1675 9.81998L32.8875 9.63998V4.35998L32.1675 4.17998V3.99998H33.2475C33.7195 3.99998 34.0915 4.15198 34.3635 4.45598C34.4675 4.57598 34.5555 4.72398 34.6275 4.89998C34.7475 4.67598 34.8755 4.49598 35.0115 4.35998C35.3715 3.99998 35.7835 3.81998 36.2475 3.81998C36.7115 3.81998 37.1195 3.92398 37.4715 4.13198C37.8235 4.33198 38.0955 4.60798 38.2875 4.95998C38.4075 4.72798 38.5355 4.53998 38.6715 4.39598C39.0395 4.01198 39.4515 3.81998 39.9075 3.81998C40.3315 3.81998 40.7115 3.90798 41.0475 4.08398C41.3915 4.25998 41.6555 4.49998 41.8395 4.80398C42.0315 5.10798 42.1275 5.43998 42.1275 5.79998V9.63998L42.8475 9.81998V9.99998H41.2875C40.5675 9.99998 40.2075 9.63998 40.2075 8.91998V5.79998C40.2075 5.27998 40.1275 4.89198 39.9675 4.63598C39.8075 4.37198 39.6275 4.23998 39.4275 4.23998C39.1955 4.23998 38.9595 4.39998 38.7195 4.71998C38.6235 4.87198 38.5395 5.03198 38.4675 5.19998V9.63998L39.1875 9.81998V9.99998H35.8275V9.81998L36.5475 9.63998V5.79998C36.5475 5.27998 36.4675 4.89198 36.3075 4.63598C36.1475 4.37198 35.9675 4.23998 35.7675 4.23998C35.5355 4.23998 35.2995 4.39998 35.0595 4.71998C34.9635 4.87198 34.8795 5.03198 34.8075 5.19998V9.63998L35.5275 9.81998V9.99998H32.1675V9.81998Z" />
      <path d="M46.8098 10.18C45.7858 10.18 44.9898 9.89998 44.4218 9.33998C43.8538 8.77198 43.5698 7.99198 43.5698 6.99998C43.5698 6.35198 43.6938 5.78798 43.9418 5.30798C44.1898 4.82798 44.5338 4.45998 44.9738 4.20398C45.4218 3.94798 45.9338 3.81998 46.5098 3.81998C47.0858 3.81998 47.5898 3.92798 48.0218 4.14398C48.4618 4.35998 48.7978 4.65198 49.0298 5.01998C49.2698 5.37998 49.3898 5.77998 49.3898 6.21998C49.3898 6.41998 49.3698 6.59198 49.3298 6.73598L49.2698 6.93998C48.9258 7.01198 48.5538 7.07198 48.1538 7.11998C47.3138 7.23998 46.4658 7.29998 45.6098 7.29998C45.6098 8.22798 45.7378 8.89198 45.9938 9.29198C46.2498 9.68398 46.6018 9.87998 47.0498 9.87998C47.3618 9.87998 47.6498 9.80798 47.9138 9.66398C48.1858 9.51198 48.3978 9.31598 48.5498 9.07598C48.7098 8.82798 48.7898 8.57598 48.7898 8.31998H49.0898C49.0898 8.63998 48.9938 8.94398 48.8018 9.23198C48.6098 9.51998 48.3418 9.75198 47.9978 9.92798C47.6538 10.096 47.2578 10.18 46.8098 10.18ZM45.6098 6.99998C46.1218 6.99998 46.5378 6.97998 46.8578 6.93998C46.9938 6.93198 47.1378 6.91198 47.2898 6.87998C47.2978 6.83998 47.3178 6.75198 47.3498 6.61598C47.3898 6.41598 47.4098 6.20398 47.4098 5.97998C47.4098 5.31598 47.3218 4.83198 47.1458 4.52798C46.9698 4.21598 46.7578 4.05998 46.5098 4.05998C45.9098 4.05998 45.6098 5.03998 45.6098 6.99998Z" />
      <path
        d="M10.181 0.912115L10.9222 1.65338C11.0003 1.73148 11.0003 1.85812 10.9222 1.93622L9.76533 3.09313C9.72782 3.13064 9.67695 3.15171 9.6239 3.15171L8.88264 3.15171C8.77218 3.15171 8.68264 3.06217 8.68264 2.95171L8.68264 2.21045C8.68264 2.1574 8.70371 2.10653 8.74122 2.06902L9.89813 0.912115C9.97623 0.83401 10.1029 0.83401 10.181 0.912115Z"
        strokeWidth="0.2"
      />
    </svg>
  );
}
