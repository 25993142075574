import { useDiscountCategories } from 'api/discount-categories/api';
import { DiscountCategoryDto } from 'api/discount-categories/types';
import { ROUTE_PATHS } from 'app/router/Router';
import { generatePath } from 'react-router';
import { getMuiIcon, Item } from './SidebarConfig';

export const useCategoriesInSidebar = () => {
  const categories = useDiscountCategories();

  if (!categories.isLoading) {
    return categories?.data?.map(category => convertCategoryToItem(category)) ?? [];
  }
};

function convertCategoryToItem(category: DiscountCategoryDto): Item {
  return {
    title: category.name,
    path: generatePath(ROUTE_PATHS.user.categorizedDiscounts, { discountCategoryId: category.id }),
    icon: getMuiIcon(category.iconName),
  };
}
