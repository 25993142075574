export enum Role {
  Admin = 'Admin',
  User = 'User',
  DiscomeAdmin = 'DiscomeAdmin',
}

export type Claims = {
  email: string;
  roles: Role[];
  tenantId: string;
  userId: string;
};
