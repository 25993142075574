import { Paginated } from 'api/pagination';
import { useApiClientContext } from 'app/contexts/ApiClientContext';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { DiscountCategoryDto } from './types';

export function useDiscountCategoriesWithPagination(paginationQueryString: string) {
  const { client } = useApiClientContext();

  return useQuery<Paginated<DiscountCategoryDto>>(
    ['discount-categories', paginationQueryString],
    async () => (await client.get(`/DiscountCategories/paginated?${paginationQueryString}`)).data,
    {
      keepPreviousData: true,
    },
  );
}

export function useDiscountCategories() {
  const { client } = useApiClientContext();

  return useQuery<DiscountCategoryDto[]>(
    ['discount-categories'],
    async () => (await client.get('/DiscountCategories')).data,
    {
      keepPreviousData: true,
    },
  );
}

export function useDiscountCategory(id: string) {
  const { client } = useApiClientContext();

  return useQuery<DiscountCategoryDto>(
    ['discount-categories', id],
    async () => (await client.get(`/DiscountCategories/${id}`)).data,
    {
      keepPreviousData: true,
    },
  );
}

export function useCreateDiscountCategory() {
  const { client } = useApiClientContext();
  const queryClient = useQueryClient();

  return useMutation((value: DiscountCategoryDto) => client.post('/DiscountCategories', value), {
    onSuccess: () => queryClient.invalidateQueries('discount-categories'),
  });
}

export function useDeleteDiscountCategory() {
  const queryClient = useQueryClient();
  const { client } = useApiClientContext();

  return useMutation((id: string) => client.delete(`/DiscountCategories/${id}`), {
    onSuccess: () => queryClient.invalidateQueries(['discount-categories']),
  });
}

export function useUpdateDiscountCategory() {
  const queryClient = useQueryClient();
  const { client } = useApiClientContext();

  return useMutation((value: DiscountCategoryDto) => client.put(`/DiscountCategories`, value), {
    onSuccess: () => queryClient.invalidateQueries(['discount-categories']),
  });
}
