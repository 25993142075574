import { loadable } from 'app/router/loadable';
import { lazy } from 'react';

const UserManagementPage = loadable(
  lazy(() =>
    import('./UserManagementPage').then(({ UserManagementPage }) => ({
      default: UserManagementPage,
    })),
  ),
);

const AddUsersPage = loadable(
  lazy(() =>
    import('./AddUsersPage').then(({ AddUsersPage }) => ({
      default: AddUsersPage,
    })),
  ),
);

export const USER_ROUTES = [
  { index: true, element: <UserManagementPage /> },
  { path: 'add-users', element: <AddUsersPage /> },
];
