import { Checkbox, CheckboxProps, FormControlLabel, FormGroup, FormHelperText } from '@mui/material';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  label: string | React.ReactElement;
  name: TName;
  control: Control<TFieldValues>;
} & Omit<CheckboxProps, 'onChange'>;

export function FormCheckboxField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ control, name, label, ...props }: Props<TFieldValues, TName>) {
  return (
    <Controller<TFieldValues, TName>
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormGroup>
          <FormControlLabel control={<Checkbox {...field} {...props} checked={field.value} />} label={label} />
          {error?.message && <FormHelperText error>{error.message}</FormHelperText>}
        </FormGroup>
      )}
    />
  );
}
