import * as Icons from '@mui/icons-material';
import { useTheme } from '@mui/material';
import React from 'react';

type IconType = typeof import('@mui/icons-material/index');

type Props = {
  name: string;
  color?: string;
  size?: number | string;
  marginLeft?: number | string;
  marginRight?: number | string;
  className?: string;
};

export function DynamicIcon({ name, color, size, marginLeft, marginRight, className }: Props) {
  const theme = useTheme();

  if (!Icons[name as keyof IconType]) {
    return null;
  }

  return React.createElement(Icons[name as keyof IconType], {
    className,
    style: { color, fontSize: size, marginLeft: parseMargin(marginLeft), marginRight: parseMargin(marginRight) },
  });

  function parseMargin(value?: string | number) {
    return typeof value === 'number' ? theme.spacing(value) : value;
  }
}
