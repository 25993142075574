// material
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export function Logo({ sx }: BoxProps) {
  return (
    <Box sx={{ width: 48, ...sx }}>
      <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.34639 15.9999C3.60455 15.9999 2.94443 15.8113 2.36604 15.4341C1.78765 15.0443 1.32871 14.4785 0.989215 13.7367C0.649725 12.9823 0.47998 12.0707 0.47998 11.0019C0.47998 9.39247 0.850905 8.16024 1.59275 7.30523C2.34718 6.43764 3.35936 6.00385 4.6293 6.00385C5.23284 6.00385 5.76094 6.12959 6.21359 6.38106C6.40219 6.46908 6.59709 6.59482 6.79827 6.75827V3.08047L5.66663 2.79756V2.51465H8.11851C9.25014 2.51465 9.81595 3.08047 9.81595 4.2121V15.1512L10.9476 15.4341V15.717H9.25014C8.49572 15.717 7.91104 15.4844 7.49611 15.0192C7.32008 14.8306 7.18176 14.5917 7.08117 14.3025C6.91772 14.6168 6.71025 14.8997 6.45878 15.1512C5.89296 15.717 5.18883 15.9999 4.34639 15.9999ZM5.10082 15.3398C5.56604 15.3398 5.98726 15.0884 6.36447 14.5854C6.50278 14.3968 6.64738 14.1453 6.79827 13.831V7.32409C6.65996 7.18578 6.51536 7.06004 6.36447 6.94688C6.02498 6.69541 5.69807 6.56967 5.38372 6.56967C4.84306 6.56967 4.42184 6.88401 4.12007 7.5127C3.83087 8.14138 3.68627 9.30445 3.68627 11.0019C3.68627 12.6239 3.8183 13.7555 4.08235 14.3968C4.35897 15.0255 4.69846 15.3398 5.10082 15.3398Z"
          fill="#00AB55"
        />
        <path
          d="M13.721 0.212132C13.8381 0.0949747 14.0281 0.0949747 14.1452 0.212132L15.5528 1.61974C15.67 1.7369 15.67 1.92685 15.5528 2.04401L13.563 4.03385C13.5067 4.09011 13.4304 4.12172 13.3509 4.12172L11.9433 4.12172C11.7776 4.12172 11.6433 3.9874 11.6433 3.82172L11.6433 2.41411C11.6433 2.33454 11.6749 2.25824 11.7311 2.20198L13.721 0.212132Z"
          fill="#00AB55"
        />
      </svg>
    </Box>
  );
}
