import { useAuth0 } from '@auth0/auth0-react';
import { Grid } from '@mui/material';
import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { LoadingScreen } from 'ui/LoadingScreen';
import { Claims } from '../types/claims';

const claimsNamespace = 'https://discome.io/Claims/';

interface ContextValue {
  claims?: Claims;
  isLoading: boolean;
}

const defaultValue: ContextValue = {
  isLoading: true,
};

export const UserContext = createContext<ContextValue>(defaultValue);

export function UserContextProvider({ children }: PropsWithChildren<any>) {
  const [value, setValue] = useState<ContextValue>(defaultValue);
  const { getIdTokenClaims, isAuthenticated, user, loginWithRedirect, isLoading } = useAuth0();

  useEffect(() => {
    if (isLoading) {
      setValue({ isLoading: true });
      return;
    }

    if (!isAuthenticated) {
      loginWithRedirect();
      return;
    }

    const fetchdata = async () => {
      setValue({ isLoading: true });
      const tokenClaims = await getIdTokenClaims();

      setValue({
        claims: {
          tenantId: tokenClaims[`${claimsNamespace}TenantId`],
          email: tokenClaims.email!,
          roles: tokenClaims[`${claimsNamespace}Roles`],
          userId: tokenClaims.sub,
        },
        isLoading: false,
      });
    };

    fetchdata();
  }, [getIdTokenClaims, isAuthenticated, user, loginWithRedirect, isLoading]);

  return (
    <UserContext.Provider value={value}>
      {value.isLoading ? (
        <Grid sx={{ minHeight: '100vh', maxWidth: '100%' }} container alignItems={'center'} justifyContent={'center'}>
          <Grid item>
            <LoadingScreen />
          </Grid>
        </Grid>
      ) : (
        children
      )}
    </UserContext.Provider>
  );
}

export const useUserContext = () => useContext(UserContext);
