import { Suspense } from 'react';
import { LoadingScreen } from 'ui/LoadingScreen';

export const loadable =
  <T extends {}>(Component: React.FC<T>) =>
  (props: T) =>
    (
      <Suspense fallback={<LoadingScreen />}>
        <Component {...props} />
      </Suspense>
    );
