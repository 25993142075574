type Routes = { [key: string]: string | Routes };

export const routePaths = <T extends Routes>(rawPaths: T, rootPath: string = '/'): T => {
  return Object.entries(rawPaths).reduce((prev, [key, value]) => {
    const prefix = rawPaths.root === '/' ? '/' : `${rootPath}${rawPaths.root}/`;
    if (key === 'root') {
      return { ...prev, [key]: `/${value}`.replace('//', '/') };
    }
    if (typeof value === 'string') {
      return { ...prev, [key]: `${prefix}${value}` };
    }
    if (typeof value === 'object') {
      return { ...prev, [key]: routePaths(value, prefix) };
    }
    return { ...prev, [key]: value };
  }, {}) as T;
};
