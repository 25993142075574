import { TextField, TextFieldProps } from '@mui/material';
import { Control, Controller, FieldError, FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { interpolatedError } from './errors';

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  label: string;
  name: TName;
  control: Control<TFieldValues>;
} & Omit<TextFieldProps, 'error' | 'onChange'>;

export function FormTextField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ control, name, label, ...props }: Props<TFieldValues, TName>) {
  const { t } = useTranslation();

  return (
    <Controller<TFieldValues, TName>
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          sx={{ flex: 1, width: '100%' }}
          label={label}
          error={!!error}
          helperText={interpolatedError(t, error as FieldError)}
          {...field}
          {...props}
          onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
        />
      )}
    />
  );
}
