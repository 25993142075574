import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { ConfettiAnimation } from 'ui/ConfettiAnimation';

interface AnimationContextValue {
  showConfetti: () => void;
}

const AnimationContext = createContext<AnimationContextValue>({
  showConfetti: () => null,
});

export const useAnimationContext = () => useContext(AnimationContext);

export const AnimationContextProvider: React.FC = ({ children }) => {
  const timeout = useRef<NodeJS.Timeout>();
  const [confettiEnabled, setConfettiEnabled] = useState(false);

  useEffect(() => {
    if (!confettiEnabled) return clearTimeout(+(timeout.current ?? 0));

    timeout.current = setTimeout(() => setConfettiEnabled(false), 1000);
    setConfettiEnabled(true);
    return () => clearTimeout(+(timeout.current ?? 0));
  }, [confettiEnabled]);

  return (
    <AnimationContext.Provider value={{ showConfetti: () => setConfettiEnabled(true) }}>
      {children}
      <ConfettiAnimation enabled={confettiEnabled} />
    </AnimationContext.Provider>
  );
};
