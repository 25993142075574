import { createContext, useContext } from 'react';
import { SettingsContextProps, ThemeMode } from '../../@types/settings';
import { useLocalStorage } from '../hooks/useLocalStorage';

const SettingsContext = createContext<SettingsContextProps>({
  themeMode: 'light',
  onChangeMode: () => {},
});

export const SettingsProvider: React.FC = ({ children }) => {
  const [settings, setSettings] = useLocalStorage('settings', {
    themeMode: 'light',
  });

  return (
    <SettingsContext.Provider
      value={{
        ...settings,
        onChangeMode,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );

  function onChangeMode(event: React.ChangeEvent<HTMLInputElement>) {
    setSettings({
      ...settings,
      themeMode: (event.target as HTMLInputElement).value as ThemeMode,
    });
  }
};

export const useSettings = () => useContext(SettingsContext);
