import { Skeleton as MuiSkeleton, SkeletonProps } from '@mui/material';

export const Skeleton: React.FC<SkeletonProps> = ({ children, ...props }) => {
  const { sx = [] } = props;

  const SkeletonSx = [
    { transform: 'unset', transformOrigin: 'unset', borderRadius: 1, maxWidth: '100%' },
    ...(Array.isArray(sx) ? sx : [sx]),
  ];

  return (
    <MuiSkeleton {...props} sx={SkeletonSx}>
      {children}
    </MuiSkeleton>
  );
};
