import { captureEvent, captureException, captureMessage, init, setUser, showReportDialog } from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing/dist/browser';

function initialize() {
  init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    beforeSend(event) {
      if (event.exception) {
        showReportDialog({ eventId: event.event_id });
      }
      return event;
    },
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications',
    ],
  });
}

export const Sentry = { initialize, captureException, captureEvent, captureMessage, setUser };
