import { Box, Button, Card, Container, Skeleton, styled, Typography } from '@mui/material';
import { useInvitationInformation } from 'api/registration/api';
import useResponsive from 'app/hooks/useResponseive';
import { ROUTE_PATHS } from 'app/router/Router';
import { Helmet } from 'react-helmet-async';
import { generatePath, Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { Logo } from 'ui/Logo';
import RegisterForm from './RegisterForm';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 5, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export function UserRegistrationPage() {
  const { invitationCode } = useParams<'invitationCode'>();
  const navigate = useNavigate();

  const mdUp = useResponsive('up', 'md');
  const employee = useInvitationInformation(invitationCode!);

  const FormContent = () => {
    if (employee.isLoading) {
      return (
        <Box sx={{ mb: 5, display: 'flex', alignItems: 'center' }}>
          <Box sx={{ flexGrow: 1 }}>
            <Skeleton height={72} />
            <Skeleton height={48} />
            <Skeleton height={56} sx={{ mt: 6 }} />
            <Skeleton height={56} />
            <Skeleton height={38} />
            <Skeleton height={48} />
          </Box>
        </Box>
      );
    }

    if (!employee.data) {
      return (
        <Box sx={{ mb: 5, display: 'flex', alignItems: 'center' }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography>Neteisingas registracijos kodas</Typography>
            <Box sx={{ mt: 5 }}>
              <Button component={RouterLink} variant="outlined" to="/">
                Prisijungti
              </Button>
            </Box>
          </Box>
        </Box>
      );
    }

    if (employee.data && employee.data.isAlreadyRegistered) {
      return (
        <Box sx={{ mb: 5, display: 'flex', alignItems: 'center' }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography>Naudotojas jau užregistruotas</Typography>
            <Box sx={{ mt: 5 }}>
              <Button component={RouterLink} variant="outlined" to="/">
                Prisijungti
              </Button>
            </Box>
          </Box>
        </Box>
      );
    }

    return (
      <>
        <Box sx={{ mb: 5, display: 'flex', alignItems: 'center' }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h4" gutterBottom>
              Sukurkite slaptažodį nauotojui {employee.data?.email}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Jūs buvote pakviesti prisijungti prie Discome Jūsų kompanijos "{employee.data?.tenantName}""
            </Typography>
          </Box>
        </Box>
        <RegisterForm invitationCode={invitationCode!} onSuccess={() => navigate(generatePath(ROUTE_PATHS.root))} />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Registruotis | Discome</title>
      </Helmet>
      <RootStyle>
        <HeaderStyle>
          <Logo />
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Jūsų nuolaidos jau čia pat!
            </Typography>
            <Typography variant="body2" sx={{ px: 5, mb: 5 }}>
              Susikurkite slaptažodį ir pradėkite naršyti nuolaidas!
            </Typography>
            <img src="/static/illustrations/illustration_register.png" alt="register" />
          </SectionStyle>
        )}

        <Container>
          <ContentStyle>
            <FormContent />
          </ContentStyle>
        </Container>
      </RootStyle>
    </>
  );
}
