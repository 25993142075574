import { useAuth0 } from '@auth0/auth0-react';
import { Skeleton } from '@mui/material';
import axios, { AxiosInstance } from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';

interface ContextValue {
  client: AxiosInstance;
}

const createClient = (accessToken: string | null) =>
  axios.create({
    headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : undefined,
  });

const ApiClientContext = createContext<ContextValue>({ client: createClient(null) });

export const ApiClientContextProvider: React.FC = ({ children }) => {
  const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!isAuthenticated) {
      setAccessToken(null);
      setLoading(false);
      return;
    }

    (async () => {
      setLoading(true);
      const token = await getAccessTokenSilently();
      setAccessToken(token);
      setLoading(false);
    })();
  }, [isAuthenticated, getAccessTokenSilently, user]);

  if (loading) {
    return <Skeleton />;
  }

  return (
    <ApiClientContext.Provider value={{ client: createClient(accessToken) }}>{children}</ApiClientContext.Provider>
  );
};

export const useApiClientContext = () => useContext(ApiClientContext);
