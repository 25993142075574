import { loadable } from 'app/router/loadable';
import { ROUTE_PATHS } from 'app/router/Router';
import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router';

export const USER_ROUTES = {
  root: 'user',
  allDiscounts: 'all-discounts',
  myDiscounts: 'my-discounts',
  viewRedeemedDiscount: 'redeemed-discount/:discountId',
  redeemDiscount: 'redeem-discount/:discountId',
  categorizedDiscounts: 'discount-category/:discountCategoryId',
};

export function userRoutes(): RouteObject {
  return {
    path: USER_ROUTES.root,
    children: [
      {
        index: true,
        element: <Navigate to={ROUTE_PATHS.user.allDiscounts} />,
      },
      {
        path: USER_ROUTES.allDiscounts,
        element: <AllDiscountsPage />,
      },
      {
        path: USER_ROUTES.myDiscounts,
        element: <MyDiscountsPage />,
      },
      {
        path: USER_ROUTES.viewRedeemedDiscount,
        element: <ViewRedeemedDiscountPage />,
      },
      {
        path: USER_ROUTES.redeemDiscount,
        element: <RedeemDiscountPage />,
      },
      {
        path: USER_ROUTES.categorizedDiscounts,
        element: <CategorizedDiscountsPage />,
      },
    ],
  };
}

const AllDiscountsPage = loadable(
  lazy(() =>
    import('./AllDiscountsPage').then(({ AllDiscountsPage }) => ({
      default: AllDiscountsPage,
    })),
  ),
);

const MyDiscountsPage = loadable(
  lazy(() =>
    import('./MyDiscountsPage').then(({ MyDiscountsPage }) => ({
      default: MyDiscountsPage,
    })),
  ),
);

const ViewRedeemedDiscountPage = loadable(
  lazy(() =>
    import('./ViewRedeemedDiscount/ViewRedeemedDiscountPage').then(({ ViewRedeemedDiscountPage }) => ({
      default: ViewRedeemedDiscountPage,
    })),
  ),
);

const RedeemDiscountPage = loadable(
  lazy(() =>
    import('./RedeemDiscount/RedeemDiscountPage').then(({ RedeemDiscountPage }) => ({
      default: RedeemDiscountPage,
    })),
  ),
);

const CategorizedDiscountsPage = loadable(
  lazy(() =>
    import('./CategorizedDiscountsPage').then(({ CategorizedDiscountsPage }) => ({
      default: CategorizedDiscountsPage,
    })),
  ),
);
