import { ErrorBoundary } from '@sentry/react';
import { AnimationContextProvider } from 'app/contexts/AnimationContext';
import { ApiClientContextProvider } from 'app/contexts/ApiClientContext';
import { AuthContextProvider } from 'app/contexts/AuthContext';
import { UserContextProvider } from 'app/contexts/UserContext';
import { Router } from 'app/router/Router';
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useRoutes } from 'react-router-dom';
import { ErrorFallback } from 'ui/ErrorFallback';
import { UserRegistrationPage } from 'user-registration/UserRegistrationPage';
import { ThemeConfig } from './app/theme';
import { GlobalStyles } from './app/theme/globalStyles';
import { ProgressBarStyle } from './ui/LoadingScreen';
import { ScrollToTop } from './ui/ScrollToTop';

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false }, mutations: { retry: false } },
});

export function App() {
  const Routing = () =>
    useRoutes([
      {
        path: '/register/:invitationCode',
        element: <UserRegistrationPage />,
      },
      {
        path: '*',
        element: <Authorized />,
      },
    ]);

  return (
    <ThemeConfig>
      <ErrorBoundary fallback={ErrorFallback}>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            autoHideDuration={3000}
          >
            <Routing />
          </SnackbarProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </ThemeConfig>
  );
}

function Authorized() {
  return (
    <AuthContextProvider>
      <UserContextProvider>
        <ApiClientContextProvider>
          <AnimationContextProvider>
            <GlobalStyles />
            <ProgressBarStyle />
            <ScrollToTop />
            <Router />
          </AnimationContextProvider>
        </ApiClientContextProvider>
      </UserContextProvider>
    </AuthContextProvider>
  );
}
