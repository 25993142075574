import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router';

export const AuthContextProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH_DOMAIN!}
      clientId={process.env.REACT_APP_AUTH_CLIENT_ID!}
      audience={process.env.REACT_APP_AUTH_AUDIENCE!}
      onRedirectCallback={handleRedirect}
      redirectUri={`${window.location.origin}${process.env.PUBLIC_URL}`}
    >
      {children}
    </Auth0Provider>
  );

  function handleRedirect(appState: AppState) {
    navigate(appState?.returnTo || window.location.pathname);
  }
};
