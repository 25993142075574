import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export function Grid(_theme: Theme) {
  return {
    MuiGrid: {
      styleOverrides: {},
    },
  };
}
