import { i18n, InitOptions, StringMap, TFunction, TOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

export const configureI18n = async (i18next: i18n, options: Partial<InitOptions>): Promise<TFunction> =>
  i18next
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: 'lt',
      ns: ['common'],
      defaultNS: 'common',
      returnObjects: true,
      backend: { loadPath: '/locales/{{lng}}/{{ns}}.json' },
      interpolation: { escapeValue: false },
      ...options,
    });

/**
 * Interpolated translation key must be: `"common:interpolated-key{value1:111}{value2:222}"`
 */
export const interpolatedTranslation = (text: string): ArgumentTypes<TFunction> =>
  [
    text.replace(/\{.*\}/g, '').trim(),
    text.match(/\{([^}]+)}/g)?.reduce<TOptions<StringMap>>((prev, curr) => {
      const [key, value] = curr.replace(/\{|}/g, '').split(':');
      return { ...prev, [key]: value };
    }, {}) ?? {},
  ] as unknown as ArgumentTypes<TFunction>;
