import { styled } from '@mui/material';
import { CreateTypes } from 'canvas-confetti';
import React, { useCallback, useEffect, useRef } from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';

interface Props {
  enabled: boolean;
}

function CanvasConfetti({ enabled }: Props) {
  const animationInstance = useRef<CreateTypes | null>(null);
  const animationEnabled = useRef<boolean>(false);

  const makeShot = useCallback((angle: number, originX: number) => {
    if (!animationInstance.current) return;

    animationInstance.current({
      particleCount: 5,
      angle,
      spread: 100,
      origin: { x: originX },
      colors: ['#1CE1D0', '#ffffff', '#6F46FA'],
    });
  }, []);

  useEffect(() => {
    animationEnabled.current = enabled;

    function nextTickAnimation() {
      makeShot(60, 0);
      makeShot(120, 1);
      animationEnabled.current && requestAnimationFrame(nextTickAnimation);
    }
    animationEnabled.current && nextTickAnimation();
  }, [enabled, makeShot]);

  return <StyledCanvasConfetti refConfetti={getInstance} />;

  function getInstance(instance: CreateTypes | null) {
    animationInstance.current = instance;
  }
}

export const ConfettiAnimation = React.memo(
  CanvasConfetti,
  (prevProps, nextProps) => prevProps.enabled === nextProps.enabled,
);

const StyledCanvasConfetti = styled(ReactCanvasConfetti)(() => ({
  position: 'fixed',
  top: 100,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 99999999,
  pointerEvents: 'none',
}));
