import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export function Container(_theme: Theme) {
  return {
    MuiContainer: {
      styleOverrides: {
        root: {},
      },
    },
  };
}
