import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Box, Button, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SettingMode } from 'ui/settings/SettingMode';
import { MIconButton } from '../../@material-extend';
import { MenuPopover } from '../../MenuPopover';

type Props = {
  userEmail: string;
};

export function AccountPopover({ userEmail }: Props) {
  const { logout } = useAuth0();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: theme => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        <Avatar alt="My Avatar" src="/static/mock-images/avatars/avatar_default.jpg" />
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ p: 0, mt: 1.5, ml: 0.75 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <SettingMode />
        </Box>

        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {userEmail}
          </Typography>
        </Box>

        <Box sx={{ m: 1, mt: 0 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={() => logout()}>
            {t('logout')}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
