import { Box, Drawer, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useUserContext } from 'app/contexts/UserContext';
import { Role } from 'app/types/claims';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { LogoFull } from 'ui/LogoFull';
import { Skeleton } from 'ui/skeletons/BaseSkeleton';
import { MHidden } from '../../@material-extend';
import { NavSection } from '../../NavSection';
import { Scrollbar } from '../../Scrollbar';
import { adminSidebarConfig, discomeAdminSidebarConfig, userSidebarConfig } from './SidebarConfig';
import { useCategoriesInSidebar } from './useCategoriesInSidebar';

type DashboardSidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export function DashboardSidebar({ isOpenSidebar, onCloseSidebar }: DashboardSidebarProps) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { claims } = useUserContext();

  const discountCategories = useCategoriesInSidebar();

  useEffect(() => {
    onCloseSidebar();
  }, [onCloseSidebar, pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          px: 2.5,
          pt: 3,
          pb: 2,
        }}
      >
        <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
          <LogoFull />
        </Box>
      </Stack>

      <NavSections isLoading={discountCategories === undefined}>
        {claims?.roles.includes(Role.User) && <NavSection navConfig={userSidebarConfig(discountCategories ?? [])} />}
        {claims?.roles.includes(Role.Admin) && <NavSection navConfig={adminSidebarConfig(t)} />}
        {claims?.roles.includes(Role.DiscomeAdmin) && <NavSection navConfig={discomeAdminSidebarConfig(t)} />}
      </NavSections>
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: DRAWER_WIDTH,
        },
      }}
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex,
    }),
  },
}));

function NavSections({ children, isLoading }: { children: React.ReactNode; isLoading?: boolean }) {
  if (isLoading) {
    return (
      <Stack spacing={5} sx={{ px: 2 }}>
        {[...Array(2)].map((_, key) => (
          <NavSectionSkeleton key={key} />
        ))}
      </Stack>
    );
  }

  return <>{children}</>;
}

function NavSectionSkeleton() {
  return (
    <Stack spacing={1}>
      <Skeleton sx={{ height: 18, width: 100 }} />
      <Skeleton sx={{ height: 48 }} />
      <Skeleton sx={{ height: 48 }} />
      <Skeleton sx={{ height: 48 }} />
    </Stack>
  );
}
