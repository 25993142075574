import axios from 'axios';
import { useMutation, useQuery } from 'react-query';
import { InvitationInformationDto, UserRegistrationDto } from './types';

export function useRegisterUser() {
  const client = axios.create();

  return useMutation((request: UserRegistrationDto) => client.post('/registration', request));
}

export function useInvitationInformation(invitationCode: string) {
  const client = axios.create();

  return useQuery<InvitationInformationDto>(
    ['pendingEmployee', invitationCode],
    async () => (await client.get<InvitationInformationDto>(`/registration/invitation-code/${invitationCode}`)).data,
  );
}
